// extracted by mini-css-extract-plugin
export var CTACard = "CTACard-module--CTACard--989e2";
export var azure = "#3c86a5";
export var blanco = "#fff";
export var button = "CTACard-module--button--de2ac";
export var buttonWrapper = "CTACard-module--buttonWrapper--06acd";
export var cloud = "#687077";
export var coral = "#cb5e65";
export var coverImage = "CTACard-module--coverImage--d9fe3";
export var defaultBorder = "10px";
export var forest = "#799884";
export var headerText = "CTACard-module--headerText--d46fe";
export var isLight = "CTACard-module--isLight--d6fdd";
export var jetBlack = "#14171c";
export var lightBase = "getColor(lightBase)";
export var lightdarkBase = "getColor(lightdarkBase)";
export var masOscuro = "#171926";
export var midnight = "#383e5f";
export var minimal = "#f4f4f8";
export var mist = "#b8c3d0";
export var mobileWidth = "760px";
export var onyx = "#191c21";
export var oscuro = "#2d3346";
export var purple = "#9264e9";
export var raven = "#1e2329";
export var steelBlue = "#2b313c";
export var text = "CTACard-module--text--a3c6d";